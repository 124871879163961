@import 'styles/_variables'

.root
  display: flex
  flex-direction: column

.icon
  width: 100px
  height: 100px
  margin-bottom: 15px
  fill: var(--accent-light)

.title
  margin-bottom: 15px
  margin-right: 25px
  color: #222
  font-size: 16px
  font-weight: 500

.btn
  border-radius: 10px
  text-transform: capitalize

.formTextarea
  margin-bottom: 15px
  min-height: 160px !important


.popupContent
  display: flex
  flex-direction: column
  align-items: center
  gap: 20px
  padding: 20px 0
  border-radius: 10px
  background-color: #fff
  overflow: hidden

  @media screen and (min-width: 768px)
    padding: 20px 50px

.textWrapper
  font-size: 15px
  text-align: center

.inactiveTimeInfo
  margin-bottom: 5px
  font-weight: 600

.buttonWrapper
  align-self: stretch

.button
  font-size: 15px
  font-weight: 600

@import 'styles/_variables'

.root
  display: grid
  grid-template-columns: 50px 1fr auto
  align-items: center
  grid-column-gap: 10px

.content
  flex: 1
  overflow: hidden

.title
  font-size: 17px
  font-weight: 500
  color: $primary-black
  margin-bottom: 2px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.subtitle
  font-size: 15px
  line-height: 1.3
  color: $grey-4
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  overflow: hidden
  text-overflow: ellipsis

.btn
  display: flex
  align-items: center
  background: var(--accent)
  padding: 10px 15px
  color: white
  border-radius: 10px
  text-align: center
  white-space: normals

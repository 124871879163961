@import 'styles/_variables'

.root
  display: flex
  flex-direction: column
  align-items: center

.popup
  max-width: 450px

.mailConfirmImg
  margin: 30px 0 10px
  max-width: 70px
  width: 100%

.title
  margin-bottom: 10px
  color: var(--primary-text, #222)
  text-align: center
  font-size: 17px
  font-weight: 600

.subtitle
  color: var(--primary-text, #222)
  text-align: center
  font-size: 15px
  font-weight: 400

.changeBtn
  color: var(--accent, #FF5C00)
  font-size: 15px
  font-weight: 400
  padding: 10px
  margin-bottom: 15px

.users
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  margin-bottom: 20px

.usersLoader
  display: flex
  align-items: center
  justify-content: center
  min-height: 80px
  margin-bottom: 20px

.userAvatar
  border: 1px solid var(--primary)
  background-position: center 20% !important

.avatarWrapper
  margin: 0 2px 10px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  border: 2px solid var(--accent)

.submitBtn
  margin-bottom: 20px
  color: var(--primary, #FFF)
  font-size: 15px
  font-weight: 500

.root
  padding: 10px 8px 80px
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 20

.overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 19
  background: rgba(0, 0, 0, 0.60)

  @media screen and (min-width: 768px)
    top: 80px
    height: calc( 100% - 80px )

.container
  display: flex
  flex-direction: column
  max-width: 450px
  width: 100%
  border-radius: 10px
  overflow: hidden
  background: var(--primary)

  @media screen and (min-width: 768px)
    max-height: 700px !important
    max-width: 450px
    margin-top: 100px
    margin-bottom: auto

.root
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.fallback
  @media (min-width: 768px)
    top: 80px
    height: calc(100vh - 80px)
    padding-bottom: 80px

@import 'styles/_variables'

.root
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  background: #fff
  width: 100%
  height: 100%
  path
    width: 10px
    height: 10px

.rootTypeInbox
  background: #D400E3

.rootTypeLike
  background: #FF0000

.rootTypeMessage
  background: var(--accent)

.rootTypeNewProfile
  background: var(--accent)

.rootTypePopular
  background: #FF9900

$primary-black: #222
$accent: #de2600
$error: #e02020

$white: #fff
$black: #000

$grey-1: #fafafa
$grey-2: #eaeaea
$grey-3: #c5c5c5
$grey-4: #8d8d8d

$main-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif

.root
    top: 90px !important
    @media (max-width: 768px)
        top: 15px !important

.toast
    padding: 10px !important
    border: 1px solid var(--secondary-light, red) !important
    border-radius: 10px !important

    @media (max-width: 480px)
        width: calc(100% - 30px) !important
        margin: 0 15px

.progress
    background: var(--accent) !important

@import 'styles/_variables'

.root
  padding-top: 15px
  display: flex
  flex-direction: column
  align-items: center

.image
  margin-bottom: 15px
  height: 75px
  width: auto
  object-fit: contain

.title
  margin-bottom: 15px
  color: var(--primary-text, #222)
  text-align: center
  font-size: 16px
  font-weight: 600
  line-height: 20px

.subtitle
  margin-bottom: 35px
  color: var(--primary-text, #222)
  text-align: center
  font-size: 15px
  font-weight: 400

.btn
  text-align: center
  font-size: 15px
  font-weight: 600

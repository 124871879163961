@import 'styles/_variables'

.root
  display: flex
  flex-direction: column
  align-items: center

.icon
  width: 70px
  height: 70px
  margin-bottom: 10px
  fill: var(--primary-text, #222)

.title
  margin-bottom: 30px
  color: var(--primary-text, #222)
  text-align: center
  font-size: 15px
  font-weight: 400

.btn
  border-radius: 10px
  font-size: 15px
  font-weight: 500

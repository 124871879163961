@import 'styles/_variables'

.root
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  width: 100%
  padding: 0 20px
  text-decoration: none
  border: none
  font-family: $main-font
  font-size: 17px
  font-weight: bold
  text-align: center
  color: $white
  cursor: pointer
  outline: none
  white-space: nowrap
  border-radius: 10px

  &.small
    height: 40px

  &.rounded
    border-radius: 32px

  &.disabled
    opacity: 0.5
    pointer-events: none
    &:hover
      opacity: 0.5

  &:hover
    @media screen and (hover: none)
      opacity: 1 !important

  @media screen and (min-width: 768px)
    font-size: 15px

.btnOutlinePrimary
  background-color: transparent
  color: var(--accent)
  border: 1px solid var(--accent)

  &.disabled
    color: var(--secondary, #5D5D5D)
    border: 1px solid var(--secondary, #5D5D5D)

  &:hover
    opacity: 0.75

.btnOutlineSecondary
  padding: 0 9px
  background-color: transparent
  border: 1px solid var(--secondary-light, #E9E9E9)
  color: var(--secondary, #5D5D5D)
  font-size: 15px
  font-weight: 500

  @media screen and (min-width: 768px)
    padding: 0 14px

  &:hover
    opacity: 0.75

.btnAccent
  color: #fff
  background-color: var(--accent)

  &:hover
    opacity: 0.75

  &:active
    background-color: var(--accent)
    opacity: 1

.btnContrast
  background-color: #fff
  color: $primary-black

  &:hover
    opacity: 0.75

  &:active
    background-color: rgba(#000, 0.1)
    opacity: 1

.btnSecondary
  background-color: $grey-2
  color: $primary-black

  &:hover
    opacity: 0.75

  &:active
    background-color: rgba(#000, 0.1)
    opacity: 1

.btnTransparent
  background-color: transparent
  padding: 0
  margin: 0
  height: inherit
  font-size: 0
  width: inherit

.btnRegular
  background-color: $white
  color: $primary-black
  font-weight: 500
  box-shadow: 0 0 3px 0 rgba(#000, 0.15)

  &:hover
    opacity: 0.75

  &:active
    background-color: rgba(#000, 0.1)
    opacity: 1

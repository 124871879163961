@import '_variables'

// Toasts

.Toastify__toast-body
  width: 96%

.Toastify__toast
  background-color: $white

.Toastify__toast-container
  z-index: 50

  @media screen and (min-width: 768px)
    left: unset
    transform: none
    width: 355px

// Carousel

.carousel-status
  bottom: 10px
  top: unset !important
  left: 10px
  right: unset !important
  border-radius: 2px
  background-color: transparent
  padding: 2px 5px !important
  text-shadow: none !important

.carousel .slider-wrapper.axis-horizontal .slider
  -webkit-backface-visibility: hidden
  -webkit-perspective: 1000
  backface-visibility: hidden
  perspective: 1000
  -webkit-transform-style: preserve-3d
  -webkit-backface-visibility: hidden

.carousel
  .slide
    backface-visibility: hidden
    perspective: 1000
    -webkit-backface-visibility: hidden
    -webkit-perspective: 1000
    -webkit-transform-style: preserve-3d
    -webkit-backface-visibility: hidden
    background: transparent !important

    iframe
      width: 100%
      margin: 0

// React toggle

.react-toggle
  touch-action: pan-x

  display: inline-block
  position: relative
  cursor: pointer
  background-color: transparent
  border: 0
  padding: 0

  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  -webkit-tap-highlight-color: rgba(0,0,0,0)
  -webkit-tap-highlight-color: transparent

.react-toggle-screenreader-only
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px

.react-toggle--disabled
  cursor: not-allowed
  opacity: 0.5
  -webkit-transition: opacity 0.25s
  transition: opacity 0.25s

.react-toggle-track
  width: 51px
  height: 31px
  padding: 0
  border-radius: 30px
  -webkit-transition: all 0.2s ease
  -moz-transition: all 0.2s ease
  transition: all 0.2s ease
  border: 2px solid #d2d2d2

  &-check
    position: absolute
    width: 14px
    height: 10px
    top: 0px
    bottom: 0px
    margin-top: auto
    margin-bottom: auto
    line-height: 0
    left: 8px
    opacity: 0
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  .react-toggle--checked-check &
    opacity: 1
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  &-x
    position: absolute
    width: 10px
    height: 10px
    top: 0px
    bottom: 0px
    margin-top: auto
    margin-bottom: auto
    line-height: 0
    right: 10px
    opacity: 1
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  .react-toggle--checked-x &
    opacity: 0

.react-toggle--checked
  .react-toggle-track
    background-color: var(--primary)
    border: 2px solid var(--primary)

.react-toggle-thumb
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms
  position: absolute
  top: 1px
  left: 1px
  width: 28px
  height: 28px
  border-radius: 50%
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05)
  border: solid 1px #d2d2d2
  background-color: #ffffff

  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box

  -webkit-transition: all 0.25s ease
  -moz-transition: all 0.25s ease
  transition: all 0.25s ease

  .react-toggle--checked &
    left: 27px

@import 'styles/_variables'
@import 'styles/_mixins'

.root
  position: relative
  display: flex
  flex-direction: column
  width: 100%

.textarea
  border-radius: 4px
  border: 1px solid $grey-2
  background-color: $white
  font-size: 15px
  color: $primary-black
  min-height: 120px
  padding: 15px
  width: 100%
  resize: none
  outline: none
  font: inherit

  @include placeholder
    color: $grey-4
    font-size: 15px

.textareaWithCounter
  padding-right: 30px !important

.maxSymbols
  position: absolute
  top: 0px
  right: 10px
  font-size: 10px
  line-height: 1.2
  color: $grey-4
  margin-top: 7px

.errorText
  position: absolute
  bottom: -20px
  left: 0
  line-height: 1.2
  color: $error
  font-size: 12px

  &.noSymbolsLeftText
    bottom: 0

@import 'styles/_variables'

.wrapper
  height: 100vh
  background-color: var(--primary)
  display: flex
  flex-direction: column
  justify-content: center

.logoWrapper
  padding: 20px
  display: flex
  align-items: center
  justify-content: center
  width: 100%

.header
  padding: 20px

.main
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.content
  text-align: center
  max-width: 360px

.title
  font-size: 70px
  color: $grey-4
  margin-bottom: 5px
  font-weight: normal

.text
  font-weight: bold
  font-size: 17px
  text-align: center
  color: $grey-4
  margin-bottom: 20px


.root
  display: flex
  flex-direction: column
  align-items: center
  gap: 20px
  border-radius: 10px
  background-color: #fff
  overflow: hidden

  @media screen and (min-width: 768px)
    padding: 20px 50px

.image
  height: 55px
  width: auto

.textWrapper
  text-align: center
  color: var(--primary-text, #222)
  font-size: 15px
  font-weight: 400

.title
  margin-bottom: 10px
  font-size: 18px
  font-weight: 600

.buttonWrapper
  align-self: stretch

.button
  font-size: 15px
  font-weight: 600

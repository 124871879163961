@import 'styles/_variables'

.root
  display: flex
  flex-direction: column
  align-items: center

.iconBox
  display: flex
  align-items: center
  padding: 40px 0 20px

.icon
  border-radius: 70px
  border: 2px solid var(--accent-light)

.lines
  fill: var(--accent-light)

.linesAccent
  fill: var(--accent)
  line
    stroke: var(--accent-light)

.title
  margin-bottom: 10px
  color: var(--accent)
  text-align: center
  font-size: 18px
  font-weight: 600
  line-height: 150%

.text
  margin-bottom: 10px
  color: #222
  text-align: center
  font-size: 16px
  font-weight: 400

.btn
  border-radius: 10px

@import 'styles/_variables'

.root
  display: flex
  flex-direction: column
  align-items: center

.icon
  width: 100px
  height: 100px
  margin-bottom: 15px
  fill: var(--accent-light)

.title
  margin-bottom: 15px
  color: var(--accent, #FF5C00)
  text-align: center
  font-size: 20px
  font-weight: 600
  line-height: 150%

.text
  margin-bottom: 20px
  color: #222
  text-align: center
  font-size: 16px
  font-weight: 500

.btn
  border-radius: 10px
  text-transform: capitalize

@import 'styles/_variables'

.root
  display: flex
  flex-direction: column
  align-items: center

.header
  padding: 30px 0 10px
  display: grid
  align-items: center
  grid-template-columns: 1fr 70px auto 70px 1fr

.sendIconWrapper
  position: relative
  width: 40px
  min-width: 40px
  height: 2px
  background: var(--accent-light, #FFD3B6)

.sendIcon
  width: 27px
  height: 27px
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.avatar
  border: 2px solid var(--accent-light, #FFD3B6)

.linesIcon
  width: 100%
  fill: var(--accent-light, #FFD3B6)

.title
  margin-bottom: 10px
  color: var(--accent)
  text-align: center
  font-size: 18px
  font-weight: 600

.subtitle
  margin-bottom: 10px
  color: #222
  text-align: center
  font-size: 15px
  font-weight: 400

.email
  padding: 10px
  color: #222
  text-align: center
  font-size: 15px
  font-weight: 600
  border-radius: 10px
  background: var(--primary-light, #F5F5F5)
  width: 100%
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.changeBtn
  color: var(--accent, #FF5C00)
  font-size: 15px
  line-height: 1
  font-weight: 400
  padding: 10px 20px

.submitBtn
  font-size: 15px
  font-weight: 500

@import 'styles/_variables'

.root
  position: fixed
  z-index: 9
  bottom: 75px
  padding: 0 10px
  width: 100%
  display: flex
  justify-content: center

.container
  padding: 10px
  border-radius: 10px
  border: 1px solid var(--secondary-light, #E9E9E9)
  background: #FFF
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15)

  display: grid
  grid-template-columns: 36px 1fr auto
  grid-column-gap: 10px
  align-items: center

.icon
  width: 36px

.content
  display: flex
  flex-direction: column
  width: 100%
  overflow: hidden

.title
  color: #222
  font-size: 13px
  font-weight: 400
  margin-bottom: 5px

.email
  color: var(--primary-text, #222)
  font-family: "SF Pro Display"
  font-size: 13px
  font-style: normal
  font-weight: 600
  line-height: normal
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.button
  padding: 3px 15px
  min-width: 48px
  height: 32px
  text-align: center
  font-size: 13px
  font-weight: 500

@import "styles/_variables"

.root
  display: flex
  flex-direction: column

.lineBtn
  padding: 7px 20px 20px

.line
  width: 50px
  height: 4px
  border-radius: 36px
  background: #E2E2E2

.header
  display: flex
  align-items: center
  padding: 0 15px 20px
  border-bottom: 1px solid #DFDFDF

.headerLeft
  margin-right: 20px
  display: grid
  grid-template-columns: 30px 1fr
  grid-gap: 10px

.favicon
  display: flex
  align-items: center
  justify-content: center
  width: 30px
  height: 30px

.faviconImage
  width: 100%
  height: 100%

.siteInfo
  display: flex
  flex-direction: column

.siteName
  text-transform: capitalize
  color: #000
  font-size: 15px
  font-weight: 500

.siteDomain
  color: #828282
  font-size: 12px
  font-weight: 500

.button
  margin-left: auto
  background: var(--accent)
  padding: 10px
  border-radius: 10px
  font-size: 15px
  font-weight: 500
  white-space: wrap

.main
  padding: 0 20px
  display: flex
  flex-direction: column
  align-items: center

.description
  padding: 20px 25px
  color: #000
  text-align: center
  font-size: 15px
  font-weight: 400

.guide
  max-width: 260px
  width: 100%
  padding: 50px 20px 20px
  background-image: url('../../../../../assets/mobile-preview.png')
  background-size: cover
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 15px

.guideMock
  border-radius: 10px
  background: #D0D0D0
  aspect-ratio: 1/1
  display: flex
  align-items: center
  justify-content: center

.guideMockImage
  width: 100%
  height: 100%
  object-fit: cover

.wizardRoot
  display: flex
  flex-direction: column
  align-items: center
  max-width: 350px
  width: 100%
  margin: 0 auto
  padding: 40px 20px 0

.title
  margin-bottom: 5px
  font-size: 16px
  font-weight: 600
  color: var(--accent)
  text-align: center

.text
  margin-bottom: 20px
  font-size: 15px
  font-weight: 400
  color: var(--primary-text, #222)
  text-align: center

.wizardButton
  margin-bottom: 20px
  background: var(--accent)
  padding: 10px
  border-radius: 10px
  font-size: 15px
  font-weight: 500
  white-space: wrap
  width: 90%

.imagesWrapper
  height: 90px
  width: 100%
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-column-gap: 3%
  align-items: flex-end
  margin-bottom: 20px

.imageWrapper
  width: 100%
  height: auto
  aspect-ratio: 1/1
  padding: 1px
  border: 2px solid var(--accent)
  border-radius: 50%
  overflow: hidden

.image
  width: 100%
  height: 100%
  object-fit: contain

.noSpace
  padding-bottom: 0

.loader
  display: flex
  align-items: center
  justify-content: center
  height: 110px

@import 'styles/_variables'

.root
  display: flex
  align-items: center

.icon
  min-width: 40px
  margin-right: 15px

.title
  font-size: 17px
  font-weight: 500
  color: $primary-black
  margin-bottom: 2px

.subtitle
  font-size: 15px
  line-height: 1.3
  color: $grey-4

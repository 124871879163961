.root
  position: relative
  background-color: #EAEAEA
  border-radius: 50%

.badge
  position: absolute
  bottom: 0
  right: 0
  width: 39%
  height: 39%
  border: 2px solid #fff
  border-radius: 50%

.offline
  position: absolute
  bottom: 0
  right: 0
  background: #909090
  border: 3px solid #fff
  border-radius: 50%
  width: 33%
  height: 33%
  max-width: 18px
  max-height: 18px

.online
  background: #399541
